export const cols = [
  { label: '消息模板名称', prop: 'templateName' },
  { label: '消息模板编码', prop: 'templateCode' },
  { label: '触发条件名称', prop: 'sceneDesc' },
  { label: '触发条件编码', prop: 'sceneCode' },
  { label: '消息类型', prop: 'messageTypeName' },
  { label: '具体类型', slotName: 'specificTypeSlot' },
  { label: '消息内容', prop: 'context' },
  { label: '状态', prop: 'isEnabled', slotName: 'isEnabledSlot' },
  { label: '操作', prop: 'operation', slotName: 'operationSlot' }
]
